<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <div class="text-center mb-3">
          <v-avatar size="150">
            <img alt="user" src="/images/Icon.png" />
          </v-avatar>
        </div>
        <v-card class="mx-auto text-center" max-width="500">
          <v-card-title>
            <div class="text-center mx-auto">
              <div class="text-h6 info--text">Daily maintenance from 11pm to 1am</div>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="mb-3">To make sure we provide top notch service consistently, our team performs maintenance every single day and updates our codes during night time. Thank you for your understanding.</div>
            <div>{{ countdownMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isDone || setting && setting.debug"
              text
              large
              :to="{ name: 'PageMainHome' }"
              color="primary"
              >{{ $t("action.returnHome") }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    maintenanceEndTime: null,
    countdownMessage: "",
    setInterval: null,
    isDone: false,
    hours: null,
    minutes: null,
    seconds: null,
    distance: 1,
  }),
  computed: mapState({
    setting: (state) => state.setting.data,
  }),
  mounted() {
    this.tick();
    this.setInterval = setInterval(this.tick.bind(this), 1000);
  },
  methods: {
    updateRemaining(distance) {
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      if (this.hours > 2) {
        this.isDone = true;
        clearInterval(this.setInterval);
        this.$router.push({ name: "PageMainHome" });
      }
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countdownMessage = `Ends in ${this.hours}h ${this.minutes}m ${this.seconds}s.`;
    },
    tick() {
      this.distance = this.$moment("01:00:00", "hh:mm:ss")
        .add(1, "days")
        .diff(this.$moment());
      this.updateRemaining(this.distance);

      if (this.distance === 0) {
        this.isDone = true;
        clearInterval(this.setInterval);
        this.$router.push({ name: "PageMainHome" });
      }
    },
    setMaintenanceEndTime() {
      const currentTime = this.$moment();
      const maintenanceStart = this.$moment().set({
        hour: 23,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const maintenanceEnd = this.$moment()
        .set({ hour: 1, minute: 0, second: 0, millisecond: 0 })
        .add(1, "day");
      if (
        currentTime.isAfter(maintenanceStart) &&
        currentTime.isBefore(maintenanceEnd)
      ) {
        this.maintenanceEndTime = maintenanceEnd;
      } else {
        this.maintenanceEndTime = maintenanceStart;
      }
    },
    updateCountdown() {
      const now = this.$moment();
      const timeDiff = this.maintenanceEndTime.diff(now);
      console.log(timeDiff);
      if (timeDiff > 0) {
        const duration = this.$moment.duration(timeDiff);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        this.countdownMessage = `Maintenance will end in ${hours} hours, ${minutes} minutes, ${seconds} seconds.`;
      } else {
        this.isDone = true;
        this.countdownMessage =
          "Maintenance has ended. The page will be available soon.";
        this.$router.push({ name: "PageMainHome" });
        clearInterval(this.setInterval);
      }
    },
  },
  destroy() {
    clearInterval(this.setInterval);
  },
};
</script>
